import React, { useEffect, useState } from 'react';
import { Button, Center, Spinner, useColorMode } from '@chakra-ui/react';
import { getDatabase, ref, get } from 'firebase/database';

const RedirectPage = () => {
  const [link, setLink] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { colorMode } = useColorMode();

  useEffect(() => {
    const db = getDatabase();
    const linkRef = ref(db, 'telegram/settings/links/onlineBotLink');

    get(linkRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          setLink(snapshot.val());
          // Automatic redirect
          window.location.href = snapshot.val();
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  const handleButtonClick = () => {
    if (link) {
      window.location.href = link;  // Directly assign to window.location.href for external links
    }
  };

  const botName = link?.replace("https://t.me/", "");

  return (
    <Center height="100vh">
      <Button
        colorScheme={colorMode === 'dark' ? 'purple' : 'blue'}
        size="lg"
        onClick={handleButtonClick}
        isDisabled={!link}
        width="50vw"  // Set width to 50% of the viewport width
        height="20vh"  // Optionally set the height as well
        fontSize="2xl"
        padding="6"
        boxShadow="lg" // Adding a shadow for better visibility
        _hover={{ bg: colorMode === 'dark' ? 'purple.700' : 'blue.500' }} // Hover effect
      >
        Online Bot: @{botName}
      </Button>
    </Center>
  );
};

export default RedirectPage;
