import React from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import Navbar from "components/navbar";
import LendingNavbar from "components/landing-navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes";
import { db, analytics, logEvent } from "../../firebaseContent/index";
import { getDatabase, DataSnapshot, get, set } from "firebase/database";
import { ref, onValue, off } from "firebase/database";
import MainDashboard from "views/admin/default";
import LandingPage from "views/landing/index";
import { useNavigate } from "react-router-dom";

export default function LandingLayout(props: { [x: string]: any }) {
  const { ...rest } = props;
  const { telegramBotUsername } = useParams<{ telegramBotUsername: string }>();
  const [link, setLink] = React.useState<string | null>(null);
  const [affiliateData, setAffiliateData] = React.useState<any>(null);
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Partner Dashboard");
  const [data, setData] = React.useState<string | null>(null);
  const navigation = useNavigate();

  const url = window.location.href;
  const partnerRef = `https://t.me/astridainews`;

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  React.useEffect(() => {
    const db = getDatabase();
    const linkRef = ref(db, "telegram/settings/links/onlineBotLink");

    get(linkRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          setLink(snapshot.val());
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  React.useEffect(() => {
    if (telegramBotUsername) {
      const db = getDatabase();
      const affiliateRef = ref(db, `telegram/bots/${telegramBotUsername}/web`);
      const sessionsRef = ref(
        db,
        `telegram/bots/${telegramBotUsername}/sessions`
      );
      get(affiliateRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setAffiliateData(data);
            updateSessionCount(data);
          } else {
            console.log("No affiliate data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
      get(sessionsRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            updateSessionCount(data);
          } else {
            console.log("No affiliate data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [telegramBotUsername]);

  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  function getTodaysUTCDate() {
    const date = new Date();
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getUTCFullYear();

    return `${day}_${month}_${year}`;
  }

  const updateSessionCount = (data: any) => {
    const db = getDatabase();
    const today = getTodaysUTCDate();
    const sessionRef = ref(
      db,
      `telegram/bots/${telegramBotUsername}/stats/${today}/web`
    );

    const newSessionCount = data && data[today] ? data[today] + 1 : 1;

    set(sessionRef, newSessionCount)
      .then(() => {
        console.log("Session count updated successfully");
      })
      .catch((error) => {
        console.error("Error updating session count:", error);
      });
  };

  const getActiveRoute = (routes: RoutesType[]): string | boolean => {
    let activeRoute = "Partner Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";

  return (
    <div className="flex h-full w-full">
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:mx-[100px]`}
        >
          <div className="h-full">
            <LendingNavbar
              onOpenSidenav={() => setOpen(true)}
              brandText={affiliateData?.name || "Astrid AI"}
              secondary={getActiveNavbar(routes)}
              avatar={affiliateData?.avatar || null}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <LandingPage
                code={
                  affiliateData ? `https://t.me/${telegramBotUsername}` : link
                }
                brandName={affiliateData?.name || "Astrid AI"}
              />
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
