import React, { useState, useEffect } from "react";
import { MdOutlineCalendarToday, MdBarChart } from "react-icons/md";
import Card from "components/card";
import SeoChart from "components/charts/SeoChart";

interface Seo {
  clicks_bot: { [date: string]: number };
  clicks_website: { [date: string]: number };
}

interface SeoDataProps {
  seo?: Seo; // Include the new Seo interface here
}

const SeoData: React.FC<SeoDataProps> = ({ seo }) => {
  /* const { last7DaysSales, last7DaysWeekdays, totalEarnings } = getLast7DaysSalesAndWeekdays(seo);
  const { currentMonthSales, currentMonthDays, formattedTotalEarnings } = getCurrentMonthSalesAndDays(seo); */

  const [timePeriod, setTimePeriod] = useState("week"); // or "month"
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [botClicks, setTotalBotClicks] = useState(0);
  const [webClicks, setTotalWebsiteClicks] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Data preparation based on the time period
    let dataBot: any, dataWeb: any, days: any;

    // Data preparation based on the time period
    if (timePeriod === "week") {
      ({
        last7DaysBotClicks: dataBot,
        last7DaysWebsiteClicks: dataWeb,
        last7DaysWeekdays: days,
      } = getLast7DaysClicksAndWeekdays(seo));
    } else {
      ({
        currentMonthBotClicks: dataBot,
        currentMonthWebsiteClicks: dataWeb,
        currentMonthDays: days,
      } = getCurrentMonthClicksAndDays(seo));
    }

    setTimeout(() => {
      setChartData([
        {
          name: "astridai.co -> Bot",
          data: dataBot,
          color: "#4318FF",
        },
        {
          name: "You -> astridai.co",
          data: dataWeb,
          color: "#6AD2Fa",
        },
      ]);
    }, 0);

    setTimeout(() => {
      setChartOptions({
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        // colors:['#ff3322','#faf']
        tooltip: {
          style: {
            fontSize: "12px",
            fontFamily: undefined,
            backgroundColor: "#000000",
          },
          theme: "dark",
          onDatasetHover: {
            style: {
              fontSize: "12px",
              fontFamily: undefined,
            },
          },
        },
        xaxis: {
          categories: days,
          show: false,
          labels: {
            show: true,
            style: {
              colors: "#A3AED0",
              fontSize: "14px",
              fontWeight: "500",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          color: "black",
          labels: {
            show: false,
            style: {
              colors: "#A3AED0",
              fontSize: "14px",
              fontWeight: "500",
            },
          },
        },

        grid: {
          borderColor: "rgba(163, 174, 208, 0.3)",
          show: true,
          yaxis: {
            lines: {
              show: false,
              opacity: 0.5,
            },
          },
          row: {
            opacity: 0.5,
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        fill: {
          type: "solid",
          colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
        },
        legend: {
          show: false,
        },
        colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: "20px",
          },
        },
      });
    }, 0);

    // Compute the total clicks for bot and website
    const totalBotClicks = dataBot.reduce((acc: any, val: any) => acc + val, 0);
    const totalWebsiteClicks = dataWeb.reduce(
      (acc: any, val: any) => acc + val,
      0
    );

    // Set the total clicks
    setTimeout(() => {
      setTotalBotClicks(totalBotClicks);
      setTotalWebsiteClicks(totalWebsiteClicks);
      setIsLoading(false);
    }, 0);

    // Turn off loading state
    setIsLoading(false);
  }, [seo, timePeriod]);

  let timeout: NodeJS.Timeout;
  const toggleTimePeriod = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setTimePeriod((prev) => (prev === "week" ? "month" : "week"));
    }, 300);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Card extra="!p-[20px] text-center">
      <div className="flex justify-between">
        <button
          className="linear mt-1 flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80"
          /* onClick={toggleTimePeriod} */
        >
          <MdOutlineCalendarToday />
          <span className="text-sm font-medium text-gray-600">
            {timePeriod === "week" ? "This week" : "This month"}
          </span>
        </button>
        <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
          <MdBarChart className="h-6 w-6" />
        </button>
      </div>

      <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="flex flex-col">
          <p className="mt-[20px] text-3xl font-bold text-navy-700 dark:text-white">
            {shortenNumber(webClicks)}
          </p>
          <div className="flex flex-col items-start">
            <p className="mt-2 text-sm text-gray-600">to astridai.co</p>
          </div>
          <p className="mt-[20px] text-3xl font-bold text-navy-700 dark:text-white">
            {shortenNumber(botClicks)}
          </p>
          <div className="flex flex-col items-start">
            <p className="mt-2 text-sm text-gray-600">to Bot</p>
          </div>
        </div>
        <div className="h-full w-full">
          <SeoChart
            key={timePeriod}
            chartOptions={chartOptions}
            chartData={chartData}
          />
        </div>
      </div>
    </Card>
  );
};

// Function to get the day of the week for a given date
const getDayOfWeek = (date: Date) => {
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  return days[date.getDay()];
};

const formatToTwoDigits = (n: number) => (n < 10 ? `0${n}` : `${n}`);

// Function to get last 7 days seo and weekdays
const getLast7DaysClicksAndWeekdays = (seo: Seo) => {
  let last7DaysBotClicks: number[] = [];
  let last7DaysWebsiteClicks: number[] = [];
  let last7DaysWeekdays: string[] = [];
  let currentDate = new Date();

  for (let i = 0; i < 7; i++) {
    let formattedDate = `${formatToTwoDigits(
      currentDate.getDate()
    )}-${formatToTwoDigits(
      currentDate.getMonth() + 1
    )}-${currentDate.getFullYear()}`;

    // Add clicks for the bot
    if (seo?.clicks_bot && seo.clicks_bot[formattedDate]) {
      last7DaysBotClicks.push(seo.clicks_bot[formattedDate]);
    } else {
      last7DaysBotClicks.push(0);
    }

    // Add clicks for the website
    if (seo?.clicks_website && seo.clicks_website[formattedDate]) {
      last7DaysWebsiteClicks.push(seo.clicks_website[formattedDate]);
    } else {
      last7DaysWebsiteClicks.push(0);
    }

    // Add day of the week
    last7DaysWeekdays.push(getDayOfWeek(currentDate));

    // Go back one day
    currentDate.setDate(currentDate.getDate() - 1);
  }

  // Reverse arrays to have them in ascending order starting from 7 days ago
  last7DaysBotClicks.reverse();
  last7DaysWebsiteClicks.reverse();
  last7DaysWeekdays.reverse();

  return {
    last7DaysBotClicks,
    last7DaysWebsiteClicks,
    last7DaysWeekdays,
  };
};

const getCurrentMonthClicksAndDays = (seo: Seo) => {
  let currentMonthBotClicks: number[] = [];
  let currentMonthWebsiteClicks: number[] = [];
  let currentMonthDays: string[] = [];

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  let loopDate = new Date(currentYear, currentMonth, 1);

  while (loopDate <= currentDate) {
    let formattedDate = `${formatToTwoDigits(
      loopDate.getDate()
    )}-${formatToTwoDigits(loopDate.getMonth() + 1)}-${loopDate.getFullYear()}`;

    // Add clicks for the bot
    if (seo?.clicks_bot && seo.clicks_bot[formattedDate]) {
      currentMonthBotClicks.push(seo.clicks_bot[formattedDate]);
    } else {
      currentMonthBotClicks.push(0);
    }

    // Add clicks for the website
    if (seo?.clicks_website && seo.clicks_website[formattedDate]) {
      currentMonthWebsiteClicks.push(seo.clicks_website[formattedDate]);
    } else {
      currentMonthWebsiteClicks.push(0);
    }

    // Add day of the month
    currentMonthDays.push(`${loopDate.getDate()}`);

    // Increment day
    loopDate.setDate(loopDate.getDate() + 1);
  }

  return {
    currentMonthBotClicks,
    currentMonthWebsiteClicks,
    currentMonthDays,
  };
};

function shortenNumber(num: number) {
  if (num >= 1000000) {
    // Handle millions
    return (Math.round(num / 100000) / 10).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (num >= 1000) {
    // Handle thousands
    return (Math.round(num / 100) / 10).toFixed(1).replace(/\.0$/, "") + "K";
  } else {
    // If less than 1000, return the number as is
    return num.toString();
  }
}

export default SeoData;
