import TotalSpent from "views/admin/default/components/TotalSpent";
import SeoData from "views/admin/default/components/SeoData";
import { MdBarChart, MdAdsClick, MdWallet, MdPeople } from "react-icons/md";

import { BiLink, BiCopy, BiMoneyWithdraw } from "react-icons/bi";

import Widget from "components/widget/Widget";
import WithdrawTable from "views/admin/default/components/WithdrawTable";
import FilesTable from "./components/FilesTable";
type Deal = {
  first_sale_percentage: number;
  other_sale_percentage: number;
  referral_sale_percentage: number;
  second_sale_percentage: number;
};

type Info = {
  code: string;
  tg_id: number;
  tg_username: string;
  traffic_source: string;
};

type Sale = {
  buyer: number;
  date: number;
  partner_amount: number;
  partner_fee: number;
  purchase_amount: number;
  type: string; // You can specify the types further, like 'FIRST_BUY' | 'OTHER_BUY'
  username: string;
};

type Sales = {
  [date: string]: Sale[];
};

type SeoClicks = {
  [date: string]: number;
};

type Seo = {
  clicks_bot: SeoClicks;
  clicks_website: SeoClicks;
};

type WalletInfo = {
  address: string;
  network: string;
  token: string;
};

type WithdrawHistory = {
  amount: number;
  date: number;
  status: number;
  tx?: string; // Make this optional if not always present
  type: string;
  wallet: WalletInfo;
};

type Withdraw = {
  balance: number;
  address: string;
  history: WithdrawHistory[];
  network: string;
  token: string;
  type: string;
};

type Partner = {
  deal: Deal;
  info: Info;
  sales: Sales;
  seo: Seo;
  withdraw: Withdraw;
};

interface DashboardProps {
  partner?: Partner;
}

const calculateMetrics = (partner: Partner) => {
  // Earnings
  let earnings = 0;
  // Sales
  let sales = 0;
  let referrals = 0;

  if (partner?.sales) {
    Object.values(partner.sales).forEach((salesOnDate) => {
      salesOnDate.forEach((sale) => {
        earnings += sale.partner_amount;
      });
    });

    sales = Object.values(partner.sales).reduce(
      (acc, val) => acc + val.length,
      0
    );

    // Referrals
    const uniqueBuyers = new Set<number>();
    Object.values(partner.sales).forEach((salesOnDate) => {
      salesOnDate.forEach((sale) => {
        uniqueBuyers.add(sale.buyer);
      });
    });

    referrals = uniqueBuyers.size;
  }

  // Withdrawn
  let withdrawn = 0;
  if (partner?.withdraw?.history) {
    withdrawn = partner.withdraw.history.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );
  }

  let clicksWebsite = 0;
  let clicksBot = 0;
  if (partner && partner?.seo) {
    // Clicks Website
    if (partner?.seo?.clicks_website) {
      clicksWebsite = Object.values(partner.seo.clicks_website).reduce(
        (acc, curr) => acc + curr,
        0
      );
    }

    // Clicks Bot
    if (partner?.seo?.clicks_bot) {
      clicksBot = Object.values(partner.seo.clicks_bot).reduce(
        (acc, curr) => acc + curr,
        0
      );
    }
  }

  return { earnings, sales, withdrawn, clicksWebsite, clicksBot, referrals };
};

const transformToRowObj = (withdrawData: Withdraw) => {
  // Helper function to convert UNIX timestamp to DD:MM:YYYY MM:SS format
  const formatDate = (timestamp: number) => {
    const dateObj = new Date(timestamp * 1000);
    const dateStr = dateObj.toISOString();
    return `${dateStr.slice(8, 10)}.${dateStr.slice(5, 7)}.${dateStr.slice(
      0,
      4
    )} ${dateStr.slice(11, 16)}`;
  };

  // Helper function to convert status number to status string
  const parseStatus = (status: number) => {
    return status === 0 ? "Waiting" : status === 1 ? "Approved" : "Declined";
  };

  // Main function logic
  if (withdrawData?.history) {
    return withdrawData.history.map(
      (entry: {
        date: any;
        status: number;
        wallet: { token: any; address: any };
        amount: any;
        tx?: any;
      }) => ({
        date: formatDate(entry.date),
        status: parseStatus(entry.status),
        amount: `$${entry.amount.toLocaleString("en-US")}`,
        toLink: entry.wallet.address,
        tx: entry.status === 1 ? (entry.tx ? entry.tx : "") : "",
      })
    );
  } else if (!withdrawData?.history) {
    return [];
  }
};

const Dashboard: React.FC<DashboardProps> = ({ partner }) => {
  if (partner) {
    const metrics = calculateMetrics(partner);
    return (
      <div>
        {/* Card widget */}

        <div className="mt-3 grid grid-cols-2 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
          <Widget
            icon={<MdWallet className="h-7 w-7" />}
            title={"Balance"}
            subtitle={"$" + partner.withdraw.balance.toLocaleString("en-US")}
          />
          <Widget
            icon={<MdBarChart className="h-6 w-6" />}
            title={"Sales"}
            subtitle={metrics.sales.toString()}
          />
          <Widget
            icon={<BiMoneyWithdraw className="h-7 w-7" />}
            title={"Withdrawn"}
            subtitle={
              "$" +
              parseFloat(metrics.withdrawn.toString()).toLocaleString("en-US")
            }
          />
          <Widget
            icon={<MdAdsClick className="h-6 w-6" />}
            title={"Clicks (astridai.co)"}
            subtitle={metrics.clicksWebsite.toLocaleString("en-US")}
          />
          <Widget
            icon={<MdAdsClick className="h-7 w-7" />}
            title={"Clicks (bot)"}
            subtitle={metrics.clicksBot.toLocaleString("en-US")}
          />
          <Widget
            icon={<MdPeople className="h-6 w-6" />}
            title={"Referrals"}
            subtitle={metrics.referrals.toString()}
          />
        </div>
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-2">
          <Widget
            icon={<BiCopy className="h-7 w-7" />}
            title={"Main Link (To ASTRIDAI.CO)"}
            subtitle={"https://astridai.co?ref=" + partner.info.code}
            link={"https://astridai.co?ref=" + partner.info.code}
          />
          <Widget
            icon={<BiCopy className="h-7 w-7" />}
            title={"Direct Link"}
            subtitle={
              "https://t.me/astridaibot?start=part_" + partner.info.code
            }
            link={"https://t.me/astridaibot?start=part_" + partner.info.code}
          />
        </div>

        {/* Charts */}

        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
          <TotalSpent sales={partner.sales} />
          <SeoData seo={partner.seo} />
        </div>

        {/* Tables & Charts */}

        <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
          <WithdrawTable
            tableData={transformToRowObj(partner.withdraw)}
            withdrawal={partner.withdraw}
          />
          <FilesTable />
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default Dashboard;
