const Footer = () => {
  return (
    <div className="flex w-full flex-col items-center justify-between px-1 pb-8 pt-3 lg:px-8 xl:flex-row">
      <h5 className="mb-4 text-center text-sm font-medium text-gray-600 sm:!mb-0 md:text-lg">
        <p className="mb-4 text-center text-sm text-gray-600 sm:!mb-0 md:text-base">
          © {new Date().getFullYear()} Astrid AI. All Rights Reserved.
        </p>
      </h5>
      <div>
        <ul className="flex flex-wrap items-center gap-3 sm:flex-nowrap md:gap-10">
          <li>
            <a
              target="blank"
              href="mailto:astridaibot@gmail.com"
              className="text-base font-medium text-gray-600 hover:text-gray-600"
            >
              Support
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://docs.astridai.co/"
              className="text-base font-medium text-gray-600 hover:text-gray-600"
            >
              Docs
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://docs.astridai.co/legal/terms-of-service"
              className="text-base font-medium text-gray-600 hover:text-gray-600"
            >
              Terms of Service
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://docs.astridai.co/legal/privacy-policy"
              className="text-base font-medium text-gray-600 hover:text-gray-600"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://docs.astridai.co/legal/refund-policy"
              className="text-base font-medium text-gray-600 hover:text-gray-600"
            >
              Refund Policy
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://docs.astridai.co/legal/acceptable-use-policy"
              className="text-base font-medium text-gray-600 hover:text-gray-600"
            >
              Acceptable Use Policy
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://docs.astridai.co/legal/gdpr-compliance"
              className="text-base font-medium text-gray-600 hover:text-gray-600"
            >
              GDPR
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://docs.astridai.co/legal/ccpa"
              className="text-base font-medium text-gray-600 hover:text-gray-600"
            >
              CCPA
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
