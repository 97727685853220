import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBU0XyVptXEAMgguj3YmjpIBvyPqk8UYHA",
  authDomain: "astrid-ecosystems-v1.firebaseapp.com",
  databaseURL: "https://astrid-ecosystems-v1-default-rtdb.firebaseio.com",
  projectId: "astrid-ecosystems-v1",
  storageBucket: "astrid-ecosystems-v1.appspot.com",
  messagingSenderId: "410840275041",
  appId: "1:410840275041:web:ca8dacc5f9ac0a2e983220",
  measurementId: "G-8WHEVY5W7T",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const analytics = getAnalytics(app);

export { database as db, analytics, logEvent };
