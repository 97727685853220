import React from "react";
import { FaBook } from "react-icons/fa";
import { IoPeople } from "react-icons/io5";
import { RiRadioButtonLine } from "react-icons/ri";

import { RiMoonFill, RiSunFill } from "react-icons/ri";
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import DefaultAvatar from "assets/img/avatars/astrid.png";
import { Image } from "@chakra-ui/react";
import { MdEmail, MdBusinessCenter } from "react-icons/md";

const LendingNavbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
  avatar?: string;
}) => {
  let { onOpenSidenav, brandText, avatar } = props;
  const [darkmode, setDarkmode] = React.useState(true);

  avatar = avatar || DefaultAvatar;
  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px] flex flex-row items-center justify-between">
        <Image
          src={avatar}
          borderRadius="25%"
          boxSize="50px"
          style={{
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
        {/* Hide on small screens */}
        <p className="ml-5 shrink text-[33px] font-bold capitalize text-navy-700 dark:text-white">
          {brandText}
        </p>
      </div>

      <div className="rounded-fullpx-2 relative mt-[3px] flex h-[61px] w-[535px] flex-grow items-center justify-around gap-2 py-2 md:w-[535px] md:flex-grow-0 md:gap-1 xl:w-[535px] xl:gap-2">
        <a href="https://astridai.co/online">
          <button className="linear mt-1 flex w-full items-center justify-center gap-2 rounded-lg p-2 text-gray-600 transition duration-200 dark:active:opacity-80">
            <RiRadioButtonLine />
            <span className="text-md font-medium text-gray-600">
              Bot online
            </span>
          </button>
        </a>
        <a href="https://docs.astridai.co/affiliates/onboarding">
          <button className="linear mt-1 flex w-full items-center justify-center gap-2 rounded-lg p-2 text-gray-600 transition duration-200 dark:active:opacity-80">
            <IoPeople />
            <span className="text-md font-medium text-gray-600">
              Affiliates
            </span>
          </button>
        </a>
        <div className="hidden sm:flex sm:w-auto sm:flex-grow">
          <a href="https://docs.astridai.co">
            <button className="linear mt-1 flex w-full items-center justify-center gap-2 rounded-lg p-2 text-gray-600 transition duration-200 dark:active:opacity-80">
              <FaBook />
              <span className="text-md font-medium text-gray-600">Docs</span>
            </button>
          </a>
          <a href="mailto:astridaibot@gmail.com">
            <button className="linear mt-1 flex w-full items-center justify-center gap-2 rounded-lg p-2 text-gray-600 transition duration-200 dark:active:opacity-80">
              <MdEmail />
              <span className="text-md font-medium text-gray-600">Support</span>
            </button>
          </a>
          <div
            className="linear mt-1 flex cursor-pointer items-center justify-center gap-2 rounded-lg text-gray-600 transition duration-200"
            onClick={() => {
              if (darkmode) {
                document.body.classList.remove("dark");
                setDarkmode(false);
              } else {
                document.body.classList.add("dark");
                setDarkmode(true);
              }
            }}
          >
            {darkmode ? (
              <RiSunFill className="h-4 w-4 text-gray-600 dark:text-gray-600" />
            ) : (
              <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-gray-600" />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default LendingNavbar;
