import { MdCancel } from "react-icons/md"; // Import your download icon
import {
  PiNumberOneBold,
  PiNumberTwoBold,
  PiNumberThreeBold,
  PiNumberFourBold,
  PiNumberFiveBold,
  PiNumberSixBold,
} from "react-icons/pi";
import { FaFreebsd } from "react-icons/fa";
import { BsCheckCircleFill, BsFillTicketFill } from "react-icons/bs";
import Card from "components/card";

const PartnerLevels = (props: { code?: string; brandName?: string}) => {
  let {code, brandName } = props;
  if (!brandName) brandName = "Astrid AI"
  return (
    <div>
      <h4 className="mb-12 mt-24 text-4xl font-bold text-navy-700 dark:text-white">
        💫 Create FaceSwaps photos and videos in seconds
      </h4>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
        <Card extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}>
          <div className="h-full w-full">
            <div className="relative overflow-hidden rounded-xl">
              <img
                src="/howto/1.jpg"
                className="mb-3 h-full w-full rounded-md 3xl:h-full 3xl:w-full"
                alt=""
              />
            </div>
            <button className="absolute right-3 top-3 flex items-center justify-center rounded-md bg-white p-2 text-brand-500 hover:cursor-pointer">
              <div className="flex h-full w-full items-center justify-center rounded-md text-xl hover:bg-gray-50 dark:text-navy-900">
                <PiNumberOneBold className="text-brand-500" />
              </div>
            </button>

            <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
              <div className="mb-2">
                <p className="text-2xl font-bold text-navy-700 dark:text-white">
                  1. Send a Face Picture
                </p>
                <div className="mt-1 text-lg font-medium text-gray-600 md:mt-2">
                  <span>
                    Choose a face which you want to add to another photo or
                    video and send it to the bot
                  </span>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
              <a href={code}>
                <button className="linear rounded-md bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
                  Start
                </button>
              </a>
            </div>
          </div>
        </Card>
        <Card extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}>
          <div className="h-full w-full">
            <div className="relative overflow-hidden rounded-xl">
              <img
                src="/howto/2.jpg"
                className="mb-3 h-full w-full rounded-md 3xl:h-full 3xl:w-full"
                alt=""
              />
            </div>
            <button className="absolute right-3 top-3 flex items-center justify-center rounded-md bg-white p-2 text-brand-500 hover:cursor-pointer">
              <div className="flex h-full w-full items-center justify-center rounded-md text-xl hover:bg-gray-50 dark:text-navy-900">
                <PiNumberTwoBold className="text-brand-500" />
              </div>
            </button>

            <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
              <div className="mb-2">
                <p className="text-2xl font-bold text-navy-700 dark:text-white">
                  2. Send Target Photo or Video
                </p>
                <div className="mt-1 text-lg font-medium text-gray-600 md:mt-2">
                  <span>
                    Send a photo or a video where you want the face to be
                    replaced
                  </span>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
              <a href={code}>
                <button className="linear rounded-md bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
                  Start
                </button>
              </a>
            </div>
          </div>
        </Card>
        <Card extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}>
          <div className="h-full w-full">
            <div className="relative overflow-hidden rounded-xl">
              <img
                src="/howto/3.jpg"
                className="mb-3 h-full w-full rounded-md 3xl:h-full 3xl:w-full"
                alt=""
              />
            </div>
            <button className="absolute right-3 top-3 flex items-center justify-center rounded-md bg-white p-2 text-brand-500 hover:cursor-pointer">
              <div className="flex h-full w-full items-center justify-center rounded-md text-xl hover:bg-gray-50 dark:text-navy-900">
                <PiNumberThreeBold className="text-brand-500" />
              </div>
            </button>

            <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
              <div className="mb-2">
                <p className="text-2xl font-bold text-navy-700 dark:text-white">
                  Magic happens ✨
                </p>
                <div className="mt-1 text-lg font-medium text-gray-600 md:mt-2">
                  <span>
                    After a few seconds of waiting, you will receive your
                    faceswap media
                  </span>
                </div>
              </div>
            </div>

            <a href={code}>
              <button className="linear rounded-md bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
                Try for Free
              </button>
            </a>
          </div>
        </Card>
      </div>

      <h4 className="mb-4 mt-24 text-4xl font-bold text-navy-700 dark:text-white">
        🧠 Features
      </h4>
      <p className="mb-12 font-dm text-lg font-medium text-gray-600">
        {brandName} is the best AI Faceswap creation platform. Find out all
        available features and how to use them{" "}
        <b>
          <a href="https://docs.astridai.co/product-overview/basic-features">
            here
          </a>
        </b>
      </p>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
        <Card extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}>
          <div className="h-full w-full">
            <div className="relative overflow-hidden rounded-xl">
              <img
                src="/howto/4.jpg"
                className="mb-3 h-full w-full rounded-md 3xl:h-full 3xl:w-full"
                alt=""
              />
            </div>
            <button className="absolute right-3 top-3 flex items-center justify-center rounded-md bg-white p-2 text-brand-500 hover:cursor-pointer">
              <div className="flex h-full w-full items-center justify-center rounded-md text-xl hover:bg-gray-50 dark:text-navy-900">
                <PiNumberOneBold className="text-brand-500" />
              </div>
            </button>

            <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
              <div className="mb-2">
                <p className="text-2xl font-bold text-navy-700 dark:text-white">
                  Face Swaps
                </p>
                <div className="mt-1 text-lg font-medium text-gray-600 md:mt-2">
                  <span>Swap faces on photos and videos in seconds</span>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
              <a href={code}>
                <button className="linear rounded-md bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
                  Create FaceSwap
                </button>
              </a>
            </div>
          </div>
        </Card>
        <Card extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}>
          <div className="h-full w-full">
            <div className="relative overflow-hidden rounded-xl">
              <img
                src="/howto/5.jpg"
                className="mb-3 h-full w-full rounded-md 3xl:h-full 3xl:w-full"
                alt=""
              />
            </div>
            <button className="absolute right-3 top-3 flex items-center justify-center rounded-md bg-white p-2 text-brand-500 hover:cursor-pointer">
              <div className="flex h-full w-full items-center justify-center rounded-md text-xl hover:bg-gray-50 dark:text-navy-900">
                <PiNumberTwoBold className="text-brand-500" />
              </div>
            </button>

            <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
              <div className="mb-2">
                <p className="text-2xl font-bold text-navy-700 dark:text-white">
                  Occlusion
                </p>
                <div className="mt-1 text-lg font-medium text-gray-600 md:mt-2">
                  <span>
                    {brandName}'s Occlusion solves the problem of faceswaps when
                    objects are in front of the face
                  </span>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
              <a href={code}>
                <button className="linear rounded-md bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
                  Check it out
                </button>
              </a>
            </div>
          </div>
        </Card>
        <Card extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}>
          <div className="h-full w-full">
            <div className="relative overflow-hidden rounded-xl">
              <img
                src="/howto/6.jpg"
                className="mb-3 h-full w-full rounded-md 3xl:h-full 3xl:w-full"
                alt=""
              />
            </div>
            <button className="absolute right-3 top-3 flex items-center justify-center rounded-md bg-white p-2 text-brand-500 hover:cursor-pointer">
              <div className="flex h-full w-full items-center justify-center rounded-md text-xl hover:bg-gray-50 dark:text-navy-900">
                <PiNumberThreeBold className="text-brand-500" />
              </div>
            </button>

            <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
              <div className="mb-2">
                <p className="text-2xl font-bold text-navy-700 dark:text-white">
                  Voice Lab 🎙
                </p>
                <div className="mt-1 text-lg font-medium text-gray-600 md:mt-2">
                  <span>
                    Clone anyone's voice with 10-second audio and swap voices as
                    well as faces
                  </span>
                </div>
              </div>
            </div>

            <a href={code}>
              <button className="linear rounded-md bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
                Learn More 📚
              </button>
            </a>
          </div>
        </Card>

        <Card extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}>
          <div className="h-full w-full">
            <div className="relative overflow-hidden rounded-xl">
              <img
                src="/howto/7.jpg"
                className="mb-3 h-full w-full rounded-md 3xl:h-full 3xl:w-full"
                alt=""
              />
            </div>
            <button className="absolute right-3 top-3 flex items-center justify-center rounded-md bg-white p-2 text-brand-500 hover:cursor-pointer">
              <div className="flex h-full w-full items-center justify-center rounded-md text-xl hover:bg-gray-50 dark:text-navy-900">
                <PiNumberFourBold className="text-brand-500" />
              </div>
            </button>

            <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
              <div className="mb-2">
                <p className="text-2xl font-bold text-navy-700 dark:text-white">
                  Face Enhance ⬆️
                </p>
                <div className="mt-1 text-lg font-medium text-gray-600 md:mt-2">
                  <span>
                    We use Face Enhancement AI to make the output video quality
                    as better as possible. Compare similar services and ours and
                    you will see the difference
                  </span>
                </div>
              </div>
            </div>

            <a href={code}>
              <button className="linear rounded-md bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
                Compare
              </button>
            </a>
          </div>
        </Card>
        <Card extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}>
          <div className="h-full w-full">
            <div className="relative overflow-hidden rounded-xl">
              <img
                src="/howto/8.jpg"
                className="mb-3 h-full w-full rounded-md 3xl:h-full 3xl:w-full"
                alt=""
              />
            </div>
            <button className="absolute right-3 top-3 flex items-center justify-center rounded-md bg-white p-2 text-brand-500 hover:cursor-pointer">
              <div className="flex h-full w-full items-center justify-center rounded-md text-xl hover:bg-gray-50 dark:text-navy-900">
                <PiNumberFiveBold className="text-brand-500" />
              </div>
            </button>

            <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
              <div className="mb-2">
                <p className="text-2xl font-bold text-navy-700 dark:text-white">
                  Advanced Options ⚙️
                </p>
                <div className="mt-1 text-lg font-medium text-gray-600 md:mt-2">
                  <span>
                    If you are an expirienced user and simple face swaps is not
                    enough for you, we can offer 5+ advanced AI models for
                    FaceSwaps, Face and Frame Enhancce
                  </span>
                </div>
              </div>
            </div>

            <a href={code}>
              <button className="linear rounded-md bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
                Start
              </button>
            </a>
          </div>
        </Card>
        <Card extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}>
          <div className="h-full w-full">
            <div className="relative overflow-hidden rounded-xl">
              <img
                src="/howto/9.jpg"
                className="mb-3 h-full w-full rounded-md 3xl:h-full 3xl:w-full"
                alt=""
              />
            </div>
            <button className="absolute right-3 top-3 flex items-center justify-center rounded-md bg-white p-2 text-brand-500 hover:cursor-pointer">
              <div className="flex h-full w-full items-center justify-center rounded-md text-xl hover:bg-gray-50 dark:text-navy-900">
                <PiNumberSixBold className="text-brand-500" />
              </div>
            </button>

            <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
              <div className="mb-2">
                <p className="text-2xl font-bold text-navy-700 dark:text-white">
                  Data Protection 🔒
                </p>
                <div className="mt-1 text-lg font-medium text-gray-600 md:mt-2">
                  <span>
                    Your data is yours. We never share it with anyone and we
                    remove all the generated media from our servers after we
                    finish processing them
                  </span>
                </div>
              </div>
            </div>

            <a href="https://docs.astridai.co/legal/privacy-policy">
              <button className="linear rounded-md bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
                Learn more
              </button>
            </a>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PartnerLevels;
