import { Routes, Route } from "react-router-dom";
import AdminLayout from "layouts/admin";
import LandingLayout from "layouts/landing";
import AuthLayout from "layouts/auth";
import OnlineLayout from "layouts/telegram/online";
const App = () => {
  return (
    <Routes>
      {/* <Route path="partner/" element={<AuthLayout />} />
      <Route path="partner/default/*" element={<AdminLayout />} />
      <Route path="telegram/subscription/*" element={<SubLayout />} /> */}
      <Route path="/" element={<LandingLayout />} />
      <Route path="/r/:telegramBotUsername" element={<LandingLayout />} />
      <Route path="online/" element={<OnlineLayout />} />
    </Routes>
  );
};

export default App;
