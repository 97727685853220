import { BsCheckCircleFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import PartnerLevels from "views/admin/default/components/custom/PartnerLevels";
const LandingPage = (props: { code?: string; brandName?: string }) => {
  let { code, brandName } = props;
  if (!brandName) brandName = "Astrid AI";
  return (
    <div>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:mx-12 lg:grid-cols-2 xl:mx-12 2xl:mx-12 2xl:grid-cols-2 3xl:mx-12 3xl:grid-cols-2">
        <div className="h-50 flex w-full flex-col justify-center text-center sm:text-center md:text-left lg:text-left">
          <h4 className="mt-5 text-6xl font-bold text-navy-700 dark:text-white">
            {brandName} - Face Swap Telegram Bot
          </h4>
          <p className="mt-5 font-dm text-xl font-medium text-gray-600">
            Telegram Bot that creates high-quality faceswaps on photos and
            videos quickly and easily.
          </p>
          <p className="mt-5 font-dm text-xl font-medium text-gray-600">
            {brandName} provides a platform for users to create deepfake videos.
            Deepfake technology is powerful and must be used responsibly.
            {brandName} is intended for entertainment and lawful purposes only.
          </p>

          <div className="mx-5 mb-10 mt-10 grid w-full grid-cols-2 md:mx-0 md:w-1/2 lg:mx-0 lg:w-1/2 2xl:mx-0 2xl:w-1/2 3xl:mx-0 3xl:w-1/2">
            <a href={code}>
              <button className="linear flex h-12 w-3/4 items-center justify-center rounded-lg bg-brand-900 p-2 text-white transition duration-200 hover:cursor-pointer hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:hover:opacity-90 dark:active:opacity-80">
                <span className="text-md font-bold">Try Free</span>
              </button>
            </a>
            <a href="https://docs.astridai.co/legal/terms-of-service">
              <button className="linear flex h-12 items-center rounded-lg bg-opacity-0 p-2 text-white transition duration-200 hover:cursor-pointer dark:hover:opacity-90 dark:active:opacity-80">
                <span className="text-md font-bold text-gray-800 dark:text-white">
                  Terms of Service
                </span>
              </button>
            </a>
          </div>
        </div>
        <div className="mt-10 flex justify-center">
          <div className="overflow-hidden rounded-3xl shadow-2xl shadow-gray-300 dark:shadow-none">
            <video autoPlay muted loop>
              <source src="/howto/result.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      <div className="mt-12">
        <PartnerLevels code={code ? code : null} brandName={brandName} />
      </div>

      <div className="mt-32 grid grid-cols-1 gap-5 md:grid-cols-1 lg:mx-12 lg:grid-cols-1 xl:mx-12 2xl:mx-12 2xl:grid-cols-1 3xl:mx-12 3xl:grid-cols-1">
        <div className="h-50 flex w-full flex-col justify-center text-center sm:text-center md:text-left lg:text-left">
          <h4 className="mt-5 text-6xl font-bold text-navy-700 dark:text-white">
            Affiliate Program
          </h4>
          <p className="mt-5 font-dm text-xl font-medium text-gray-600">
            FaceSwap Bots are the trend of 2024, and {brandName}, powered by{" "}
            <b>Astrid AI</b> is the best face swap telegram bot available on the
            market.
          </p>
          <p className="mt-5 font-dm text-xl font-medium text-gray-600">
            We will give you AI Software, Our Servers, Payment Solutions,
            Marketing Strategy and everything else you need. Your job is to
            create a bot in less than 1 minute, bring audience and convert them
            to $$$
          </p>
          <div className="mb-3 mt-5 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
            <div className="mb-2">
              <p className="text-2xl font-bold text-navy-700 dark:text-white">
                Here is what we can offer:
              </p>
              <div className="font-mediu mt-1 flex items-center text-lg text-gray-600 md:mt-2">
                <BsCheckCircleFill />
                <span className="ml-2">
                  Ecosystem trusted by 1000+ affiliates
                </span>
              </div>
              <div className="font-mediu mt-1 flex items-center text-lg text-gray-600 md:mt-2">
                <BsCheckCircleFill />
                <span className="ml-2">
                  Payment Methods for users from EU, LATAM, Middle East, Asia
                  and other 124 countries
                </span>
              </div>
              <div className="font-mediu mt-1 flex items-center text-lg text-gray-600 md:mt-2">
                <BsCheckCircleFill />
                <span className="ml-2">
                  Highest convertion rates on the market. One-time and
                  subscription models combined.
                </span>
              </div>
              <div className="font-mediu mt-1 flex items-center text-lg text-gray-600 md:mt-2">
                <BsCheckCircleFill />
                <span className="ml-2">Revenue Share starting from 40%</span>
              </div>
              <div className="font-mediu mt-1 flex items-center text-lg text-gray-600 md:mt-2">
                <BsCheckCircleFill />
                <span className="ml-2">
                  Payouts from $30, directly to your wallet
                </span>
              </div>
            </div>
          </div>

          <div className="mx-5 mb-10 mt-10 grid w-full grid-cols-2 md:mx-0 md:w-1/2 lg:mx-0 lg:w-1/2 2xl:mx-0 2xl:w-1/2 3xl:mx-0 3xl:w-1/2">
            <a href={code}>
              <button className="linear flex h-12 w-3/4 items-center justify-center rounded-lg bg-brand-900 p-2 text-white transition duration-200 hover:cursor-pointer hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:hover:opacity-90 dark:active:opacity-80">
                <span className="text-md font-bold">Join</span>
              </button>
            </a>
            <a href="https://docs.astridai.co/affiliates/onboarding">
              <button className="linear flex h-12 items-center rounded-lg bg-opacity-0 p-2 text-white transition duration-200 hover:cursor-pointer dark:hover:opacity-90 dark:active:opacity-80">
                <span className="text-md font-bold text-gray-800 dark:text-white">
                  Read Docs 📚
                </span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
