import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "components/fields/InputField";

export default function SignIn() {
  const navigation = useNavigate();
  const [partnerCode, setPartnerCode] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = () => {
    if (partnerCode && password) {
      navigation(`/partner/default?code=${partnerCode}&pass=${password}`);
    }
  };

  return (
    <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="text-4xl font-bold text-navy-700 dark:text-white">
          Who are the Partners?
        </h4>
        <h4 className="mt-2text-md text-navy-700 dark:text-white">
          Are you an influencer or a website owner? We have a product you can sell and get up to 70% of all the income your audience can make - lifetime.
        </h4>
        <h4 className="mt-5 mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Partner Sign In
        </h4>
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Partner Code*"
          placeholder="PARTNERCODE"
          id="email"
          type="text"
          onChange={(e) => setPartnerCode(e.target.value)}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Password"
          id="password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="mb-4 flex items-center justify-between px-2">
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href="mailto:astrinaibot@gmail.com"
          >
            Forgot Password?
          </a>
        </div>
        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={handleSubmit}
        >
          Sign In
        </button>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not a partner yet?
          </span>
          <a
            href="https://forms.gle/nbfKE3ZCshXVrcWn7"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Become a partner
          </a>
        </div>
      </div>
    </div>
  );
}
