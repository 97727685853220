import React from "react";
import {
  MdFileDownload,
  MdWebAsset,
  MdOutlinePermMedia,
  MdOutlineVideoFile,
} from "react-icons/md"; // Import your download icon
import { FaInstagram, FaWeebly, FaFilePdf } from "react-icons/fa";
import {
  AiOutlineInstagram,
  AiOutlineFileZip,
  AiFillFileZip,
} from "react-icons/ai";
import { BsFileEarmarkTextFill, BsFileEarmarkImage } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import Card from "components/card";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";

export default function FilesTable() {
  const files = [
    {
      name: "Everything",
      icon: <MdOutlinePermMedia />,
      path: "https://firebasestorage.googleapis.com/v0/b/astridai.appspot.com/o/partners%2Fmaterials%2Fall.zip?alt=media&token=5e5c6abb-3d1b-46f8-bcb7-e43e948785cb",
      download_icon: <AiFillFileZip className="me-2" />,
    },
    {
      name: "Banners/Images",
      icon: <BsFileEarmarkImage />,
      path: "https://firebasestorage.googleapis.com/v0/b/astridai.appspot.com/o/partners%2Fmaterials%2Fimages.zip?alt=media&token=423473af-bbe9-485b-a847-0f9639fc5bb6",
      download_icon: <AiFillFileZip className="me-2" />,
    },
    {
      name: "Videos",
      icon: <MdOutlineVideoFile />,
      path: "https://firebasestorage.googleapis.com/v0/b/astridai.appspot.com/o/partners%2Fmaterials%2Fvideos.zip?alt=media&token=e0cbe419-d240-4fe9-a108-3450de1d6f02",
      download_icon: <AiFillFileZip className="me-2" />,
    },
    {
      name: "Website Basic Post",
      icon: <MdWebAsset />,
      path: "https://firebasestorage.googleapis.com/v0/b/astridai.appspot.com/o/partners%2Fmaterials%2FBasicReview.pdf?alt=media&token=5cb48e5e-36c4-44bb-a82a-e006e1e269d1",
      download_icon: <FaFilePdf className="me-2" />,
    },
    {
      name: "Website Post Review",
      icon: <MdWebAsset />,
      path: "https://firebasestorage.googleapis.com/v0/b/astridai.appspot.com/o/partners%2Fmaterials%2FAstrid%20AI%20Review.pdf?alt=media&token=7c4b8830-1109-429d-80d3-e1f62d151bc9",
      download_icon: <FaFilePdf className="me-2" />,
    },
    {
      name: "Website Post Adult",
      icon: <MdWebAsset />,
      path: "https://firebasestorage.googleapis.com/v0/b/astridai.appspot.com/o/partners%2Fmaterials%2FAdultReview.pdf?alt=media&token=05187c08-6867-4ab0-9667-07b50fedd118",
      download_icon: <FaFilePdf className="me-2" />,
    },
    {
      name: "Instagram Shoutouts",
      icon: <AiOutlineInstagram />,
      path: "https://firebasestorage.googleapis.com/v0/b/astridai.appspot.com/o/partners%2Fmaterials%2FIGShoutouts.txt?alt=media&token=054abf80-1d6d-4f79-8b53-a793992744cd",
      download_icon: <BsFileEarmarkTextFill className="me-2" />,
    },
    {
      name: "Twitter/X Shoutouts",
      icon: <FaXTwitter />,
      path: "https://firebasestorage.googleapis.com/v0/b/astridai.appspot.com/o/partners%2Fmaterials%2FTwitterShoutouts.txt?alt=media&token=f4286c0f-b6aa-4268-9534-c4e4c1b144a5",
      download_icon: <BsFileEarmarkTextFill className="me-2" />,
    },
  ];

  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Promotion Materials
        </div>
      </div>
      <div className="mt-4 overflow-x-scroll xl:overflow-x-hidden">
        <Table className="w-full">
          <Tbody>
            {files.map((file, index) => (
              <Tr key={index}>
                <Td className="py-2 pr-4">{file.icon}</Td>
                <Td className="py-2 pr-4">{file.name}</Td>
                <Td className="w-1/4 py-2">
                  <a href={file.path} download>
                    <button className="linear mt-1 flex w-full items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80">
                      {file.download_icon}
                      <span className="text-sm font-medium text-gray-600">
                        Download
                      </span>
                    </button>
                  </a>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </Card>
  );
}
