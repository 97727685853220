import React, { useState, useEffect } from "react";
import { MdOutlineCalendarToday, MdBarChart } from "react-icons/md";
import Card from "components/card";
import LineChart from "components/charts/LineChart";
import LineAreaChart from "components/charts/LineAreaChart";

interface Sale {
  partner_amount: number;
  [key: string]: any;
}

interface Sales {
  [key: string]: Sale[];
}

interface TotalSpentProps {
  sales?: Sales;
}

const TotalSpent: React.FC<TotalSpentProps> = ({ sales }) => {
  /* const { last7DaysSales, last7DaysWeekdays, totalEarnings } = getLast7DaysSalesAndWeekdays(sales);
  const { currentMonthSales, currentMonthDays, formattedTotalEarnings } = getCurrentMonthSalesAndDays(sales); */

  const [timePeriod, setTimePeriod] = useState("month"); // or "month"
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Variable declarations
    let data: any, days: any, earnings: any;

    // Data preparation based on the time period
    if (timePeriod === "week") {
      ({
        last7DaysSales: data,
        last7DaysWeekdays: days,
        totalEarnings: earnings,
      } = getLast7DaysSalesAndWeekdays(sales));
      console.log("Week:", data, days, earnings);
    } else {
      ({
        currentMonthSales: data,
        currentMonthDays: days,
        totalEarnings: earnings,
      } = getCurrentMonthSalesAndDays(sales));
      console.log("Month:", data, days, earnings);
    }

    setTimeout(() => {
      setChartData([
        {
          ...JSON.parse(
            JSON.stringify({
              name: "Sales",
              data: data,
              color: "#4318FF",
            })
          ),
        },
      ]);
    }, 0);

    setTimeout(() => {
      setChartOptions({
        legend: {
          show: false,
        },
        theme: {
          mode: "light",
        },
        chart: {
          type: "line",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        tooltip: {
          style: {
            fontSize: "12px",
            backgroundColor: "#000000",
          },
          theme: "dark",
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
        grid: {
          show: false,
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: "#A3AED0",
              fontSize: "12px",
              fontWeight: "500",
            },
          },
          type: "text",
          categories: days,
        },
        yaxis: {
          show: false,
        },
      });
    }, 0);

    setTimeout(() => {
      setTotalEarnings(Number(earnings));
      setIsLoading(false);
    }, 0);

    setIsLoading(false);
  }, [sales, timePeriod]);

  let timeout: NodeJS.Timeout;
  const toggleTimePeriod = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setTimePeriod((prev) => (prev === "week" ? "month" : "week"));
    }, 300);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Card extra="!p-[20px] text-center">
      <div className="flex justify-between">
        <button
          className="linear mt-1 flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80"
          onClick={toggleTimePeriod}
        >
          <MdOutlineCalendarToday />
          <span className="text-sm font-medium text-gray-600">
            {timePeriod === "week" ? "This week" : "This month"}
          </span>
        </button>
        <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
          <MdBarChart className="h-6 w-6" />
        </button>
      </div>

      <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="flex flex-col">
          <p className="mt-[20px] text-3xl font-bold text-navy-700 dark:text-white">
            ${totalEarnings.toLocaleString("en-US")}
          </p>
          <div className="flex flex-col items-start">
            <p className="mt-2 text-sm text-gray-600">Earnings</p>
          </div>
        </div>
        <div className="h-full w-full">
          <LineAreaChart
            key={timePeriod}
            chartOptions={chartOptions}
            chartData={chartData}
          />
        </div>
      </div>
    </Card>
  );
};

// Function to get the day of the week for a given date
const getDayOfWeek = (date: Date) => {
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  return days[date.getDay()];
};

const formatToTwoDigits = (n: number) => (n < 10 ? `0${n}` : `${n}`);

// Function to get last 7 days sales and weekdays
const getLast7DaysSalesAndWeekdays = (sales: Sales) => {
  let last7DaysSales: number[] = [];
  let last7DaysWeekdays: string[] = [];
  let totalEarnings = 0;
  let currentDate = new Date();

  for (let i = 0; i < 7; i++) {
    let formattedDate = `${formatToTwoDigits(
      currentDate.getDate()
    )}-${formatToTwoDigits(
      currentDate.getMonth() + 1
    )}-${currentDate.getFullYear()}`;

    // Add sales
    if (sales && sales[formattedDate]) {
      // Add sales
      last7DaysSales.push(sales[formattedDate].length);

      // Calculate earnings for the day and add to totalEarnings
      const dailyEarnings = sales[formattedDate].reduce(
        (acc, sale) => acc + sale.partner_amount,
        0
      );
      totalEarnings += dailyEarnings;
    } else {
      last7DaysSales.push(0);
    }

    // Add day of the week
    last7DaysWeekdays.push(getDayOfWeek(currentDate));

    // Go back one day
    currentDate.setDate(currentDate.getDate() - 1);
  }

  // Reverse arrays to have them in ascending order starting from 7 days ago
  last7DaysSales.reverse();
  last7DaysWeekdays.reverse();

  return {
    last7DaysSales,
    last7DaysWeekdays,
    totalEarnings,
  };
};

const getCurrentMonthSalesAndDays = (sales: Sales) => {
  let currentMonthSales: number[] = [];
  let currentMonthDays: string[] = [];
  let totalEarnings = 0;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  let loopDate = new Date(currentYear, currentMonth, 1);

  while (loopDate <= currentDate) {
    let formattedDate = `${formatToTwoDigits(
      loopDate.getDate()
    )}-${formatToTwoDigits(loopDate.getMonth() + 1)}-${loopDate.getFullYear()}`;

    if (sales && sales[formattedDate]) {
      // Add sales
      currentMonthSales.push(sales[formattedDate].length);

      // Calculate earnings for the day and add to totalEarnings
      const dailyEarnings = sales[formattedDate].reduce(
        (acc, sale) => acc + sale.partner_amount,
        0
      );
      totalEarnings += dailyEarnings;
    } else {
      currentMonthSales.push(0);
    }

    // Add day of the month
    currentMonthDays.push(`${loopDate.getDate()}`);

    // Increment day
    loopDate.setDate(loopDate.getDate() + 1);
  }

  return {
    currentMonthSales,
    currentMonthDays,
    totalEarnings,
  };
};

export default TotalSpent;
