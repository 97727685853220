import { Button } from "@chakra-ui/react";
import Card from "components/card";
import React from "react";
import { BiCopy, BiSolidCopy } from "react-icons/bi";

const Widget = (props: {
  icon: JSX.Element;
  title: string;
  subtitle: string;
  link?: string;
}) => {
  const { icon, title, subtitle, link } = props;

  const [isCopied, setIsCopied] = React.useState(false);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isCopied) {
      timeout = setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied]);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(subtitle);
      setIsCopied(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px]">
      {!link && (
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
            <span className="flex items-center text-brand-500 dark:text-white">
              {icon}
            </span>
          </div>
        </div>
      )}

      {link && (
        <div className="ml-[18px] flex h-[90px] flex-row items-center">
          {/* Other content here */}
          {link && (
            <Button variant="brand" onClick={handleCopyClick}>
              <div className="justify-self-end rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                <span className="flex items-center font-bold text-brand-500 dark:text-white">
                  {isCopied ? "COPIED" : "COPY"}
                </span>
              </div>
            </Button>
          )}
        </div>
      )}

      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {subtitle}
        </h4>
      </div>
    </Card>
  );
};

export default Widget;
