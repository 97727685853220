import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Card from "components/card";
import { MdCancel, MdCheckCircle, MdOutlineError } from "react-icons/md";
import { useDisclosure } from "@chakra-ui/react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@chakra-ui/react";
import PopoverHorizon from "components/popover/index";
import { db } from "firebaseContent/index";
import { ref, onValue, off, DataSnapshot, set, get } from "firebase/database";

type RowObj = {
  status: string;
  date: string;
  amount: number;
  toLink: string;
  tx: string;
};

const columnHelper = createColumnHelper<RowObj>();

export default function ComplexTable(props: {
  tableData: any;
  withdrawal: any;
}) {
  const { tableData, withdrawal } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  let defaultData = tableData.reverse();

  const url = window.location.href;
  const code = url?.split("&")[0]?.split("code=")[1].toUpperCase() || "null";
  const pass = url?.split("&")[1]?.split("pass=")[1] || "null";

  const requestWithdrawal = async () => {
    try {
      const partner_data = ref(db, "/astridAI/bot/partners/");

      const snapshot = await get(partner_data); // await the result from get
      const user = snapshot.val();

      if (user[code]?.info.pass === pass) {
        const historyLength = user[code]?.withdraw?.history?.length || 0;
        const updatePath = `/astridAI/bot/partners/${code}/withdraw/history/${historyLength}`;
        const withdrawalData = {
          amount: user[code]?.withdraw.balance,
          date: Math.floor(new Date().getTime() / 1000), // current timestamp in milliseconds
          status: 0,
          wallet: {
            address: user[code]?.withdraw.address,
            network: user[code]?.withdraw.network,
            token: user[code]?.withdraw.token,
          },
        };
        const updatedSnapshot = ref(db, updatePath);
        await set(updatedSnapshot, withdrawalData); // await setting the new withdrawal data in Firebase
        alert(
          "Withdrawal Request sent. You will be contacted soon for the confirmation"
        );
      } else {
        alert("Incorrect credentials, please sign in again");
      }
    } catch (error) {
      console.error("Error during withdrawal request:", error);
      alert(
        "An error occurred while processing your withdrawal request. Please try again later."
      );
    }
  };

  const columns = [
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          STATUS
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          {info.getValue() === "Approved" ? (
            <MdCheckCircle className="me-1 text-green-500 dark:text-green-300" />
          ) : info.getValue() === "Declined" ? (
            <MdCancel className="me-1 text-red-500 dark:text-red-300" />
          ) : info.getValue() === "Waiting" ? (
            <MdOutlineError className="me-1 text-amber-500 dark:text-amber-300" />
          ) : null}
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">DATE</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("amount", {
      id: "amount",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          AMOUNT
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("tx", {
      id: "tx",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          TX HASH
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  if (tableData.length === 0) {
    return (
      <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
        <div className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Withdrawal History
          </div>
          {withdrawal?.balance > 1 && (
            <PopoverHorizon
              trigger={
                <button
                  className="linear z-10 mt-1 flex items-center justify-center gap-2 rounded-lg bg-[#88f0aa] p-2 text-white transition duration-200 hover:cursor-pointer hover:bg-[#81e095] active:bg-gray-200 dark:bg-[#17562b] dark:hover:opacity-90 dark:active:opacity-80"
                  onClick={onOpen}
                >
                  <span className="text-sm font-medium text-[#35744a] dark:text-white">
                    Withdraw
                  </span>
                </button>
              }
              content={
                <div>
                  <p>Your balance: ${withdrawal?.balance}</p>
                  {/* Add additional content if needed */}
                  <Button
                    onClick={requestWithdrawal}
                    className="linear mt-1 flex w-full gap-2 rounded-lg bg-lightPrimary p-2 text-gray-600 transition duration-200 hover:cursor-pointer active:bg-gray-200 dark:bg-navy-500 dark:text-lightPrimary dark:active:opacity-80"
                  >
                    Request Withdrawal
                  </Button>
                </div>
              }
            />
          )}
        </div>

        <div
          className="mt-4 overflow-x-scroll xl:overflow-x-hidden"
          style={{ maxHeight: "600px", overflowY: "auto" }}
        >
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                      >
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="table-scroll">
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>
    );
  }

  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Withdrawal History
        </div>
        {withdrawal?.balance > 1 &&
          !withdrawal.history.some(
            (item: { status: number }) => item.status === 0
          ) && (
            <PopoverHorizon
              trigger={
                <button
                  className="linear z-10 mt-1 flex items-center justify-center gap-2 rounded-lg bg-[#88f0aa] p-2 text-white transition duration-200 hover:cursor-pointer hover:bg-[#81e095] active:bg-gray-200 dark:bg-[#17562b] dark:hover:opacity-90 dark:active:opacity-80"
                  onClick={onOpen}
                >
                  <span className="text-sm font-medium text-[#35744a] dark:text-white">
                    Withdraw
                  </span>
                </button>
              }
              content={
                <div>
                  <p>Your balance: ${withdrawal?.balance}</p>
                  {/* Add additional content if needed */}
                  <Button
                    onClick={requestWithdrawal}
                    className="linear mt-1 flex w-full gap-2 rounded-lg bg-lightPrimary p-2 text-gray-600 transition duration-200 hover:cursor-pointer active:bg-gray-200 dark:bg-navy-500 dark:text-lightPrimary dark:active:opacity-80"
                  >
                    Request Withdrawal
                  </Button>
                </div>
              }
            />
          )}
        {withdrawal.history.some(
          (item: { status: number }) => item.status === 0
        ) && (
          <button
            disabled
            className="linear mt-1 flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-600 transition duration-200 hover:cursor-pointer active:bg-gray-200 dark:bg-navy-700 dark:active:opacity-80"
          >
            <span className="text-sm font-medium text-gray-600">
              Withdrawal Pending...
            </span>
          </button>
        )}
      </div>

      <div
        className="mt-4 overflow-x-scroll xl:overflow-x-hidden"
        style={{ maxHeight: "600px", overflowY: "auto" }}
      >
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="table-scroll">
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className="min-w-[150px] border-white/0 py-3  pr-4"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
}
